import { Component, Mixins } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import { VueClass } from "vue-class-component/lib/declarations";
import {
  VendorCollector,
  VendorCollectorCollection,
} from "@planetadeleste/vue-mc-gw";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<VendorCollector, VendorCollectorCollection>
>;

@Component
export default class VendorCollectorsMixin extends Mixins(TypedModelMixin) {
  obCollection = new VendorCollectorCollection();
  obModelClass = VendorCollector;
  sRoutePath = "/vendorcollectors";

  created() {
    this.onCreated();
  }
}
