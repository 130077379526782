<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12">
        <data-table
          :headers="headers"
          :items="obCollection.getModelList()"
          :last-page="serverLastPage"
          :loading="bLocalLoading"
          :options="pagination"
          :total="serverItemsLength"
          btn-action-item-key-id="id"
          btn-action-path="vendorcollectors"
          hide-btn-action-view
          is-route-name
          @delete="deleteItem"
          @update:sort-by="onSortBy"
          @update:sort-desc="onSortDesc"
          @update:options="onPagination"
          @update:page="onPage"
        >
          <template v-slot:[`item.is_collector`]="{ item }">
            {{ $t(`${item.is_collector ? "collector" : "both"}`) }}
          </template>

          <template #[`item.name`]="{ item }">
            <v-btn
              :to="{ name: 'vendorcollectors.update', params: { id: item.id } }"
              text
              color="primary"
            >
              {{ item.name }}
            </v-btn>
          </template>

          <template #[`item.actions`]="{ item }">
            <module-actions :value="item.id" @delete="deleteItem" />
          </template>
        </data-table>
      </v-col>
    </v-row>
    <vendor-collectors-form
      :key="$route.params.id"
      v-model="obItem"
      :open.sync="displayForm"
    />
  </v-col>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import VendorCollectorsMixin from "@/modules/vendorcollectors/mixins/VendorCollectorsMixin";
import VendorCollectorsForm from "@/modules/vendorcollectors/components/Form.vue";
import { DataTableHeader } from "@/mixins/DataTableMixin";
import { Product } from "@planetadeleste/vue-mc-shopaholic";
import Utils from "@/services/Utils";

@Component({
  components: {
    VendorCollectorsForm,
  },
})
export default class VendorCollectorsList extends Mixins(
  VendorCollectorsMixin
) {
  isList = true;

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      { text: "code", value: "code" },
      { text: "is.vendor.collector", value: "is_collector" },
    ];
    this.addDTHeaders(arHeaders).delDTHeader("active");

    this.index();
  }

  onRegisterEvents() {
    this.addEvent("app.branch.selected", this.index);
    this.addEvent("filters.change", this.index);
    this.addEvent("before.show.form", this.getCode);
  }

  getCode(obItem: Product) {
    if (obItem.id || obItem.code) {
      return;
    }

    Utils.nextCode("vendorcollector").then((response) => {
      this.obItem.set("code", response.data);
    });
  }
}
</script>
